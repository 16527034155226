import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import store from "../src/store";

loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(AutoComplete)
  .use(store)
  .mount("#app");
  
