import { createApp } from 'vue'
import { createStore } from 'vuex'
createApp(createStore)
// Create a new store instance.
export default createStore({
  state () {
    return {
      data:[],
      pass:[]
    }
  },
  mutations: {
    setData (state,payload) {
      state.data = payload;
    },
    passData(state,data1){
        state.pass=data1
    }
  },
  
  getters:{
    getData(state){
        return state.data;
    },
    getpass(state){
        return state.pass
    }
  }
})
